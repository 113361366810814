<template>
  <v-dialog v-model="dialog" persistent width="750">
    <v-card>
      <v-card-title>Item Profile</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-for="photo in item.item_photos"
            :key="photo.item_photo_url"
            class="d-flex child-flex"
            cols="6"
          >
            <v-img
              :src="photo.item_photo_url"
              lazy-src="@/assets/jmbcircle.png"
              aspect-ratio="1"
              class="grey lighten-2"
              @click="view_image(photo.item_photo_url)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="mt-5">
          <h1 class="mt-2 d font-weight-bold">
            <v-icon class="mr-2">mdi-cart</v-icon>Item Name:
            <span v-if="!item.is_vat" class="orange--text">
              {{ item.item_name }}</span
            >
            <span v-if="item.is_vat" class="green--text">
              {{ item.item_name }}</span
            >
          </h1>
          <v-divider class="mt-2 mb-5"></v-divider>
          <h2 class="mt-2">
            <v-icon class="mr-2">mdi-pen</v-icon> Item description:
            <span class="font-weight-regular">{{ item.item_description }}</span>
          </h2>
          <h2 class="mt-2">
            <v-icon class="mr-2">mdi-cash</v-icon> Selling Price:
            <span class="green--text">Php {{ item.selling_price }}.00</span>
          </h2>
          <h2 class="mt-2">
            <v-icon class="mr-2">mdi-cart-arrow-down</v-icon>Available Stocks:
            <span class="red--text" v-if="item.quantity == 0">
              Out of Stock</span
            >
            <span class="green--text" v-else>{{ item.quantity }}</span>
          </h2>
          <h2 class="mt-2">
            <v-icon class="mr-2">mdi-truck-cargo-container</v-icon>Batch:
            {{ item.import_batch.batch_description }}
          </h2>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('close')"> Close</v-btn>
        <v-spacer></v-spacer>
        <p class="caption grey--text">itemProfileModal.vue</p>
      </v-card-actions>
    </v-card>
    <problem-picture-modal
      :picture_url="selected_picture_url"
      picture_description=""
      v-if="enable_view_picture"
      @close="enable_view_picture = false"
    />
  </v-dialog>
</template>

<script>
import ProblemPictureModal from "../Problems/problemPictureModal.vue";
export default {
  components: { ProblemPictureModal },
  props: ["item"],
  data() {
    return {
      dialog: true,
      loading: false,
      selected_picture_url: "",
      enable_view_picture: false,
    };
  },
  methods: {
    view_image(image_url) {
      // console.log(image_url);
      this.selected_picture_url = image_url;
      this.enable_view_picture = true;
    },
    submit() {
      return;
    },
  },
};
</script>

<style lang="scss" scoped></style>
